import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Button } from '@mui/material';
import { ChevronRight } from "../../assets/ChevronRight";

const GameEvents = ({ eventObj }) => {
  const navigate = useNavigate();
  const theme = useTheme()

  return (
    <Box sx={{display: "flex", justifyContent: "center"}}>
      <Box sx={{maxWidth: "1040px"}}>
        <Box
          sx={{ textAlign: "center", my: 3 }}
        >
          <Typography sx={{ fontSize: { xs: "24pt", sm: "24pt", md: "30pt" } }} variant='h2Bold' >
            {eventObj.title}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{fontSize: {xs: "16pt", sm: "18pt", md: "20pt"}}} variant='h4Reg'>
            {eventObj.description}
          </Typography>
        </Box>
        <Box sx={{mt: 3, mx:{xs: 2 }}}>
          <img style={{ borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)" }} src={eventObj.img} alt={eventObj.title} width="100%" />
        </Box>
        <Box>
            <Box sx={{textAlign: 'center', mt: 3 }}>
                <Typography sx={{fontSize: {xs: "16pt", sm: "18pt", md: "20pt"}}} variant='h4Reg'>
                    {eventObj.tagline}
                </Typography>
            </Box>

          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Button
              onClick={() => navigate("/calendar")}
              endIcon={<ChevronRight color={theme.palette.background.default} />}
              sx={{my: 6}}
              variant='primary'
            >
              Calendar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )

}

export default GameEvents;