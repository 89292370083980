import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import Typography from "@mui/material/Typography";
import GoogleMapEmbed from "../HomePage/GoogleMapEmbed";
import facebook from '../../assets/facebook.svg';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';
import youtube from '../../assets/youtube.svg';
import discord from '../../assets/discord.svg';
import googlePlus from '../../assets/googlePlus.svg';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ContactUs = () => {
  const theme = useTheme();
  const handleSocialClick = (link) => {
    window.open(link, '_blank');
    console.log('Clicked:', link);
  }
  const contactObj = {
    phone: '(303) 766-3530',
    email: 'CollectorManiaParker@gmail.com',
    address: '19555 E Parker Square Dr.',
    address2: 'Suite 101',
    city: "Parker, CO 80134",
    hours: [
      { days: 'Monday - Friday', hours: '11am - 10pm' },
      { days: 'Saturday', hours: '10am - 9pm' },
      { days: 'Sunday', hours: '11am - 5pm' }
    ],
    socials: [
      { name: 'Facebook', icon: facebook, link: 'https://www.facebook.com/CollectorMania/' },
      { name: 'Twitter', icon: twitter, link: 'https://twitter.com/CollectorMania' },
      { name: 'Instagram', icon: instagram, link: 'https://www.instagram.com/collectormania/' },
      { name: 'Youtube', icon: youtube, link: 'https://www.youtube.com/channel/UCE7XRjR4VuqhxJ4GaSkb7uA' },
      { name: 'Discord', icon: discord, link: 'https://discord.gg/AeWzwtvz9V' },

    ]
  };


  return (
    <Box
      sx={{
        p: 2,
        mx: { xs: 0, sm: 4, md: 9 }
      }}>
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography sx={{ fontSize: { xs: "32pt", sm: "48pt", md: "60pt" } }} variant='specHeader'>Contact Us</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ textAlign: "center", mt: 3, width: {xs: "100%", lg: "1000px"}}}>
          <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }}} variant='h4Reg'>
            Reach us by phone or email. We’re a team of friendly individuals who are happy to answer any and every question you have!
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        justifyContent: { sm: "space-between", lg: "center" },
        gap: {xs: 0, lg: 20},
        display: { xs: "block", md: "flex" }
      }}>
        <Box>
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: {xs: "18pt", sm: "20pt", md: "24pt"} }} variant='h2Bold'>
              Our Phone Number
            </Typography>
            <Typography 
            sx={{ 
              fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, 
              display: "block",
              color: theme.palette.hyperLink.main, 
              textDecoration: "underline",
              cursor: "pointer"
              }} 
              variant='h4Reg'>
              {contactObj.phone}
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: {xs: "18pt", sm: "20pt", md: "24pt"} }} variant='h2Bold'>
              Our Email
            </Typography>
            <Typography 
            sx={{ 
              fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, 
              display: "block",
              color: theme.palette.hyperLink.main, 
              textDecoration: "underline",
              cursor: "pointer"
              }} 
              variant='h4Reg'>
              {contactObj.email}
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontSize: {xs: "18pt", sm: "20pt", md: "24pt"} }} variant='h2Bold'>
              Our Location
            </Typography>
            <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, display: "block" }} variant='h4Reg'>
              {contactObj.address}
            </Typography>
            <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, display: "block" }} variant='h4Reg'>
              {contactObj.address2}
            </Typography>
            <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, display: "block" }} variant='h4Reg'>
              {contactObj.city}
            </Typography>
          </Box>
          <Box sx={{ mt: 3, width: { xs: "auto", sm: "450px" } }}>
            <Typography sx={{ fontSize: {xs: "18pt", sm: "20pt", md: "24pt"} }} variant='h2Bold'>
              Our Social Media Links
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
              {
                contactObj.socials.map((social) =>
                 <Box onClick={()=>(handleSocialClick(social.link))}>
                  <img
                    style={{cursor: "pointer"}} 
                    src={social.icon} 
                    alt={social.name} />
                </Box>)
              }
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: {xs: "18pt", sm: "20pt", md: "24pt"} }} variant='h2Bold'>
            Our Store Hours
          </Typography>
          <Typography>
            {contactObj.hours.map((hour) =>
              <Box>
                <Typography sx={{mt: 2, fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, display: "block" }} variant='h4Bold'>
                  {hour.days}
                </Typography>
                <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, display: "block" }} variant='h4Reg'>
                  {hour.hours}
                </Typography>
              </Box>
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
      }}>
        <Box sx={{
          my: 7,
          width: { xs: "100%", lg: "1248px" },
          height: "450px"
        }}>
          <GoogleMapEmbed />
        </Box>
      </Box>
    </Box>
  )


}

export default ContactUs;