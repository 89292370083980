import React from 'react';
import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
const WhatIsGame = ({ gameObj }) => {
  const theme = useTheme()

  return (
    <Box>
      <Box sx={{ textAlign: "center", my:3}}>
        {gameObj.title
          ?
          <Typography
          sx={{fontSize: {xs: "24pt", sm: "24pt", md: "30pt"}}} variant='h2Bold'
          >
            {gameObj.title}
          </Typography>
          : null}
      </Box>
      <Box sx={{display: "flex", justifyContent: "center"}}>
        <Box sx={{display: "flex", flexDirection: {xs: "column", md:"row"}, gap: 3, mx: {xs: 2, md: 4, lg: 7, xl: 9}, maxWidth: "1248px"}}>
          <Box sx={{flexDirection: "column", display: "flex", gap: 3, flex: 1}}>
            <Box>
              <Typography sx={{fontSize: {xs: "16pt", sm: "18pt", md: "20pt"}}} variant='h4Reg'>
                {gameObj.description}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{fontSize: {xs: "16pt", sm: "18pt", md: "20pt"}}} variant='h4Reg'>
                {gameObj.description2}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{fontSize: {xs: "16pt", sm: "18pt", md: "20pt"}}} variant='h4Reg'>
                {gameObj.description3}
              </Typography>
            </Box>
          </Box>
          <Box sx={{flex: 1, maxWidth: "615px"}}>
            <img style={{borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)"}} width="100%" src={gameObj.image} alt={gameObj.alt} />
          </Box>
        </Box>
      </Box>
    </Box>
  )


}


export default WhatIsGame;