import React from 'react';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { List, ListItem } from '@mui/material';

const SupplyImageCard = ({ supplyCardObj }) => {
  const theme = useTheme();


  return (
    <Box sx={{
      mt:6,
      boxShadow: "0 4px 4px rgba(0, 0, 0, 15%), -8px 8px 15px rgba(53, 50, 44, 15%)",
      borderRadius: "4px",

    }}>
      <Box 
      sx={{
        width: {xs: 'auto', sm: '500px'},

      }}
      >
        <img
        width="100%" 
        src={supplyCardObj.image} 
        alt={supplyCardObj.name} />
      </Box>
      <Box sx={{textAlign: "center"}} >
        <Typography sx={{fontSize: {xs: "24pt", sm: "24pt", md: "30pt"}}} variant='h2Bold'>
          {supplyCardObj.name}
        </Typography>
        <List sx={{display: {xs: "none"}}}>
          {supplyCardObj.items.map((item) =>
            <ListItem sx={{py:0.5, px: 0}}>
              <Typography sx={{fontSize: {xs: "16pt", sm: "18pt", md: "20pt"}}} variant='h4Reg'>
                {item}
              </Typography>
            </ListItem>)}
        </List>
      </Box>

    </Box>
  )


}

export default SupplyImageCard;