import React from 'react';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

const MiniImgCard = ({miniImg}) => {
    return (
        <Box
            sx={{ textAlign:'center', borderRadius: "4px", mx:{ xs: 2, }, mt:{xs: 3}, width:'300px', boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)"}} >
            <Box sx={{mt:{xs: 3, } }}>
                <img src={miniImg.src} alt={miniImg.alt} style={{maxWidth:'300px'}}/>
            </Box>
        </Box>

    )
}

export default MiniImgCard;