import * as React from 'react';
import { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@emotion/react';
import { ChevronDown } from '../../assets/ChevronDown.jsx';
import { Accordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import  WordMark from '../../assets/WordMark.svg';
import Logo from '../../assets/Logo.svg';

const products = ["Magic: The Gathering", "Role Playing Games", "Miniature Gaming", "Board Games", "Supplies"];
const aboutUs = ["FAQs", "Contact Us"];
const hamMenu = ["Home", "Products", "Card Request Form", "Calendar", "About Us"]

const  ResponsiveAppBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElCat, setAnchorElCat] = React.useState(null);
  const [isHovered, setIsHovered] = React.useState('');
  const [prodSelected, setProdSelected] = React.useState(false);
  const [aboutSelected, setAboutSelected] = React.useState(false);
  const [resetMenus, setResetMenus] = React.useState(false);



  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAboutSelected(true)
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenUserCatMenu = (event) => {
    setProdSelected(true);
    setAnchorElCat(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setExpanded(false);
    setResetMenus(!resetMenus);
  };

  const handleCloseUserMenu = () => {
    setAboutSelected(false)
    setAnchorElUser(null);
    setResetMenus(!resetMenus);
  };
  const handleCloseCat = () => {
    setProdSelected(false);
    setAnchorElCat(null);
    setResetMenus(!resetMenus);
    console.log('closing menu', anchorElCat)
  };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleItemClick = (item) => {
        if(item.product) {
            switch (item.product) {
                case "Magic: The Gathering":
                    return () => navigate('/magic');
                case "Role Playing Games":
                    return () => navigate('/rpg');
                case "Miniature Gaming":
                    return () => navigate('/miniatures');
                case "Board Games":
                    return () => navigate('/boardgames');
                case "Supplies":
                    return () => navigate('/supplies');

            }
        } else {
            switch (item) {
                case "FAQs":
                    return () => navigate('/faq');
                case "Contact Us":
                    return () => navigate('/contact');
            }
        }
    }
    const handleSmallMenuClick = (item) => {
        switch (item) {
            case 'Home':
                return () => navigate('/');
            case 'Calendar':
                return () => navigate('/calendar');
            case 'Card Request Form':
                return () => navigate('/request');
        }
    }

    useEffect(()=> {
        handleCloseNavMenu();
    },[location])


  useEffect(() => {
    console.log('anchorElCat', anchorElCat);
    setAnchorElUser(null);
    setAnchorElCat(null);
    setAnchorElNav(null);
    setIsHovered('');
    setProdSelected(false);
    setAboutSelected(false);
  }, [resetMenus]);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      }}
    >
      <Container sx={{ p: 0}} maxWidth="100%">
        <Toolbar disableGutters>
          <Box sx={{
            display: "flex",
            flexGrow: 1,
            py: 2,
            justifyContent: "space-between",
            mx: {xs: 2, lg: 6, xl: 9 }
          }}>
            <Box
              onClick={() => navigate("/")}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 2
              }}>
              {/* Logo */}
              <img style={{cursor: "pointer"}} src={Logo} alt="Logo" />
              {/* WordMark */}
              <Box sx={{ display: { xs: "none", sm: "block" }, cursor: "pointer"}}>
                <img style={{cursor: "pointer"}} src={WordMark} alt="Wordmark" />
              </Box>
              {/* this is the hamburger menu */}
            </Box>
            <Box sx={{
              display: "flex",
              alignItems: "center",
            }}>

              <Box
                  sx={{
                      flexGrow: 1,
                      display: { xs: 'flex', lg: 'none' }
              }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="black"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'block' },
                  }}
                >
                  {hamMenu.map((item) => (
                    item === "Products" || item === "About Us" ?
                      <MenuItem sx={{ p: 0}}>
                        <Accordion 
                        expanded={item === 'Products' ? expanded === "Panel1" : expanded === "Panel2" }
                        onChange={handleChange((item === 'Products' ? "Panel1" : "Panel2"))}
                        sx={{ boxShadow: "none", width:'100%', p:0 }}>
                          <AccordionSummary 
                          expandIcon={<ChevronDown/>}

                          >
                            <Typography variant='bodyTextBold' onClick={handleItemClick(item)}>
                              {item}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {item === "Products"
                              ? 
                                products.map((product) => (
                                  <MenuItem>
                                    <Typography variant='h4Reg' onClick={handleItemClick({product})}>
                                      {product}
                                    </Typography>
                                  </MenuItem>
                                ))
                              :
                                aboutUs.map((about) => (
                                  <MenuItem sx={{
                                    width: "165px"
                                  }}>
                                    <Typography variant='h4Reg' onClick={handleItemClick(about)} >
                                      {about}
                                    </Typography>
                                  </MenuItem>
                                ))
                            }
                          </AccordionDetails>
                        </Accordion>
                      </MenuItem> :
                      <MenuItem key={item} onClick={handleCloseNavMenu}>
                        <Typography variant='bodyTextBold' textAlign="center" onClick={handleSmallMenuClick(item)} >{item}</Typography>
                      </MenuItem>
                  ))}
                </Menu>
              </Box>
              {/* %%%%%%%%%%%%%%%%%%%%%%%%%%% hamEnd &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */}
              <Box sx={{
                display: { xs: "none", lg: "flex" },
                gap: 2
              }}>
                <Box
                  onMouseEnter={() => setIsHovered('Home')}
                  onMouseLeave={() => setIsHovered('')}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    }
                  }}

                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: isHovered === 'Home' ? theme.palette.bodyTextDefault.negative : theme.palette.bodyTextDefault.main,
                    }}
                    variant="bodyTextBold"
                    onClick={() => navigate('/')}
                  >
                    Home
                  </Typography>
                </Box>
                {/* this is the products button */}
                <Box
                  onClick={handleOpenUserCatMenu}
                  onMouseEnter={() => setIsHovered('Products')}
                  onMouseLeave={() => setIsHovered('')}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    backgroundColor: (isHovered === 'Products' || prodSelected) ? theme.palette.primary.main : theme.palette.background,
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                    cursor: "pointer",
                  }}>
                  <Typography variant="bodyTextBold"
                    sx={{
                      cursor: "pointer",
                      color: (isHovered === 'Products' || prodSelected) ? theme.palette.bodyTextDefault.negative : theme.palette.bodyTextDefault.main,
                    }}
                  >
                    Products
                  </Typography>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    rotate: prodSelected ? "-180deg" : "0deg",
                    transitionDuration: "0.5s"

                  }}>
                    <ChevronDown color={(prodSelected || isHovered === "Products") ? theme.palette.bodyTextNegative.main : theme.palette.bodyTextDefault.main} />
                  </Box>
                  <Menu
                    sx={{
                      mt: '50px',
                    }}
                    id="menu-appbar"
                    anchorEl={anchorElCat}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    disableAutoFocusItem="true"
                    open={Boolean(anchorElCat)}
                    onClose={handleCloseCat}
                  >
                    {products.map((product) => (
                      <MenuItem
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.bodyTextNegative.main
                          }
                        }}
                        key={product}
                        onClick={handleItemClick({product})}>
                        {/* Typography below is the link being rendered */}
                        <Typography sx={{cursor: "pointer"}} variant='bodyTextBold' textAlign="center" >{product}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box
                  onMouseEnter={() => setIsHovered('Card')}
                  onMouseLeave={() => setIsHovered('')}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    p: 2,
                    cursor: "pointer",

                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4
                    }
                  }}>
                  <Typography
                    variant="bodyTextBold"
                    sx={{
                      cursor: "pointer",
                      color: isHovered === 'Card' ? theme.palette.bodyTextDefault.negative : theme.palette.bodyTextDefault.main,
                    }}
                    onClick={() => navigate('/request')}
                  >
                    Card Request Form
                  </Typography>
                </Box>
                <Box
                  onMouseEnter={() => setIsHovered('Calendar')}
                  onMouseLeave={() => setIsHovered('')}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    }
                  }}>
                  <Typography variant="bodyTextBold"
                    sx={{
                      cursor: "pointer",
                      color: isHovered === 'Calendar' ? theme.palette.bodyTextDefault.negative : theme.palette.bodyTextDefault.main,
                    }}
                    onClick={() => navigate('/calendar')}
                  >
                    Calendar
                  </Typography>
                </Box>
                <Box
                  onMouseEnter={() => setIsHovered('About')}
                  onMouseLeave={() => setIsHovered('')}
                  onClick={handleOpenUserMenu}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    backgroundColor: (isHovered === 'About' || aboutSelected) ? theme.palette.primary.main : theme.palette.background,
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                  }}>
                  <Typography variant="bodyTextBold" sx={{cursor: "pointer", color: (isHovered === 'About' || aboutSelected) ? theme.palette.bodyTextDefault.negative : theme.palette.bodyTextDefault.main }}>
                    About Us
                  </Typography>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    rotate: aboutSelected ? "-180deg" : "0deg",
                    transitionDuration: "0.5s"
                  }}>
                    <ChevronDown color={(aboutSelected || isHovered === "About") ? theme.palette.bodyTextNegative.main : theme.palette.bodyTextDefault.main} />
                  </Box>
                  <Menu
                    disableAutoFocusItem="true"
                    sx={{ mt: '52px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {aboutUs.map((item) => (
                      <MenuItem
                        sx={{
                          width: "165px",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.bodyTextNegative.main
                          }
                        }}
                        key={item}
                        onClick={handleItemClick(item)}>
                        {/* Typography below is the link being rendered */}
                        <Typography
                          sx={{cursor: "pointer"}}
                          variant='bodyTextBold'
                          textAlign="center"

                        >{item}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;