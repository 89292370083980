import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from "@emotion/react";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from "../../assets/ChevronRight";

const GameCard = ({ gameObj }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleOnClick = (title) => {
      switch (title){
          case 'Magic the Gathering':
              navigate('/magic');
              break;
          case 'Miniature Gaming':
              navigate('/miniatures');
              break;
          case 'Role Playing Games':
              navigate('/rpg');
              break;
      }

  }

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center", 
      flexDirection: "column",
      px: {xs: 0, sm: 3},
      py: 4,
      borderRadius: "4px",
      boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)",
    }}>
      <Box sx={{
        width: "355px"
      }}>
        <img
        width="100%"
        height="auto"
        src={gameObj.image} 
        alt={gameObj.name} />
      </Box>
      <Button
      endIcon={<ChevronRight color={theme.palette.background.default}/>}
      variant='primary'
      onClick={() => (handleOnClick(gameObj.title))}
      sx={{mt: 3}}
      >
        {gameObj.title}
      </Button>
    </Box>

  )

}

export default GameCard;