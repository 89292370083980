import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Phone } from "../../assets/Phone";
import { Email } from "../../assets/Email";
import { useTheme } from '@emotion/react';
import { Button, Link } from "@mui/material";
import { ChevronRight } from "../../assets/ChevronRight"

// sample info object
// {
//     title: 'Supplies',
//     description: 'We have a variety of supplies available for purchase. Please contact us for more information.',
//     link: {
//         type: 'phone',
//         value: '555-555-5555'
//     }
// }

const HomeInfoCard = ({ infoObj }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  


  return (
    <Box sx={{
      mx: { xs: 2, md: 9 },
      mt: 7
    }}>
      <Box>
        <Typography variant='h1Bold'>
          {infoObj.title}
        </Typography>
      </Box>
      <Box sx={{
        mt: 6,
        ml: { xs: 0, md: 10 },
        textAlign: "left"
      }}>
        <Typography variant='h2Reg'>
          {infoObj.description}
        </Typography>
        <Box>
          <Button
            onClick={() => navigate(infoObj.link)}
            endIcon={<ChevronRight color={theme.palette.background.default} />}
            variant='primary'
            sx={{ mt: 6 }}
          >
            {infoObj.ButtonName}
          </Button>
        </Box>
      </Box>
    </Box>
  )

}

export default HomeInfoCard;