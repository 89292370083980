import React from 'react';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import WhatIsGame from "./WhatIsGame";
import GameEvents from "./GameEvents";
import GamesWorkshopCard from "./GamesWorkshopCard";
import StarWarsCard from "./StarWarsCard";
import MiniImgCard from "./MiniImgCard";
import WHMain from '../../assets/Warhammer 40k Img.jpg';
import WHSecond from '../../assets/WarHammer2.jpg';
import WH_AOS_LOGO from '../../assets/aos-logo 2.jpg';
import WH_AOS from '../../assets/WarHammerAOS.jpg';
import WarCry from '../../assets/WarCry.jpg';
import KillTeam from '../../assets/KillTeam.jpg';
import UnderWorlds from '../../assets/Underworlds.jpg';
import Necromunda from '../../assets/Necromunda.jpg';
import BloodBowl from '../../assets/BloodBowl.jpg';
import MiddleEarth from '../../assets/MiddleEarth.jpg'
import SWLogo from '../../assets/StarWarsLogo.jpg';
import SWLegion from '../../assets/Legion.jpg';
import SWShatter from '../../assets/ShatterPoint.jpg';
import Xwing from '../../assets/XWing.jpg';
import MarvelLogo from '../../assets/MarvelLogo.jpg';
import Crisis from '../../assets/Crisis.jpg';
import Nolzur1 from '../../assets/NolzurM1.jpg';
import Nolzur2 from '../../assets/NolzurM2.jpg';
import Nolzur3 from '../../assets/NolzurM3.jpg';
import MiniEventImg from '../../assets/MiniEventImage.jpg';
import GamesWorkshopWarhammer40kLogo1 from "../../assets/GamesWorkshopWarhammer40kLogo1.jpg"


const MiniatureGaming = () => {
  const theme = useTheme();
  const gameObj = {
    title: 'What is Warhammer 40k?',
    description: 'Warhammer 40k is a miniature wargame made by Games Workshop. \n' +
      'Assemble an army of miniature models like warriors, vehicles, and more. They can be human, alien, or supernatural monsters. Paint them, make them your own as you sweep across the battlefield, laying waste anything that dare stand in your way! \n' +
      'Engage in combat against rival armies in a turn based tabletop fight like you’ve never seen before. Victory is yours for the taking - will you seize the crown?',
    image: WHSecond,
    alt: 'Goblin Mech Man'
  }

  const gameObj2 = {
    title: null,
    description: 'Similar to Warhammer 40k, Age of Sigmar combines medieval knights and powerful mages to wreak havoc on the battlefield. ',
    description2: 'Players build, paint and play with their own armies as hordes of warriors clash together in epic battle. Pick your favorite armies - Zombies or knights, dragons or ogres, or a concept all your own. The possibilities are limitless!',
    image: WH_AOS,
    alt: 'WarHammer Age of Sigmar'
  }

  const eventObj = {
    title: 'Miniature Gaming Events',
    description: 'We regularly schedule multiple events each week, giving everyone plenty of opportunity to play.',
    img: MiniEventImg,
    tagline: 'Check our calendar for upcoming events and tournaments and get in on the action!',
  }



  const gameInfoObjects = [
    {
      title: 'Warcry',
      img: WarCry,
      imgAlt: 'Warcry Logo'
    },
    {
      title: 'Kill Team',
      img: KillTeam,
      imgAlt: 'Kill Team Logo'
    },
    {
      title: 'Warhammer Underworlds',
      img: UnderWorlds,
      imgAlt: 'Warhammer Underworlds Logo'
    },
    {
      title: 'Necromunda',
      img: Necromunda,
      imgAlt: 'Necromunda Logo'
    },
    {
      title: 'Blood Bowl',
      img: BloodBowl,
      imgAlt: 'Blood Bowl Logo'
    },
    {
      title: 'Middle Earth',
      img: MiddleEarth,
      imgAlt: 'Middle Earth Logo'
    },
  ]

  const starWarsGameObjs = [
    {
      name: 'Star Wars: Legion',
      description: 'Star Wars: Legion invites you to join the unsung battles of the Galactic Civil War as the commander of a unique army filled with troopers, powerful ground or repulsor vehicles, and iconic characters like Darth Vader or Luke Skywalker. ',
      img: SWLegion,
      imgAlt: 'Star Wars: Legion Logo'
    },
    {
      name: 'Star Wars: Shatterpoint',
      description: 'Star Wars: Shatterpoint invites players to collect, assemble, and paint their own squads of heroes, villains, and their allies and pit them against one another in tense duels to control key battlefield objectives. ',
      img: SWShatter,
      imgAlt: 'Star Wars: Shatterpoint Logo'
    },
    {
      name: 'Star Wars: X-Wing',
      description: 'Assemble your own squadron of iconic starfighters from across the Star Wars saga and engage in fast-paced, high-stakes space combat with iconic pilots such as Luke Skywalker and Iden Versio.',
      img: Xwing,
      imgAlt: 'Star Wars: X-Wing Logo'
    }
  ]
  const marvelGames = [
    {
      name: 'Marvel: Crisis Protocol',
      description: 'Assemble your team from across the Marvel Universe and lead your superpowered forces against your friends in battles of cataclysmic proportions.',
      img: Crisis,
      imgAlt: 'Marvel: Crisis Protocol Logo'
    }
  ]

  const miniImages = [
    {
      src: Nolzur1,
      alt: 'Nolzur Mini Figure'
    },
    {
      src: Nolzur2,
      alt: 'Nolzur Mini Figure'
    },
    {
      src: Nolzur3,
      alt: 'Nolzur Mini Figure'
    },
  ]
  return (
    <>
      <Box
        sx={{ textAlign: "center", mt: 3, my: { xs: 3, lg: 6 } }}
      >
        <Typography
          sx={{ fontSize: { xs: "26pt", sm: "48pt", md: "60pt" } }} variant='specHeader'
        >
          Miniature Gaming
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", }}>
        <Box sx={{ maxWidth: "525px"}}>
          <img src={GamesWorkshopWarhammer40kLogo1}  width={"100%"} alt="Warhammer Logo" />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
        <Box sx={{ maxWidth: { xs: '100%', md: '1248px' }, px: { xs: 2, md: 6 }, }} >
          <img src={WHMain} width={"100%"} style={{ borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)" }} />
        </Box>
      </Box>

      <WhatIsGame gameObj={gameObj} />
      <Box sx={{
        mx: { xs: 2, md: 9 },
        my: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{ mx: { xs: 2 }, maxWidth: "800px" }}
          >
            {/* IMG TAG BELOW IS THE MAIN IMAGE ON THE PAGE */}
            <img src={WH_AOS_LOGO} style={{ width: "100%" }} />
          </Box>
        </Box>
        <Box>
          <WhatIsGame gameObj={gameObj2} />
        </Box>
        <Box sx={{
          mx: { xs: 2, md: 9 },
          my: 7,
        }}>
          <hr style={{
            border: `1px solid ${theme.palette.primary.main}`
          }} />
        </Box>
        <Box
          sx={{ textAlign: 'center' }}
        >
          <Typography
            sx={{ mx:2, display: 'block', fontSize: { xs: "20pt", sm: "24pt", md: "30pt" }, textAlign: 'center' }}
            variant='h2Bold'
          >
            Other Games WorkShop Games
          </Typography>
          <Typography
            sx={{mx:2, fontSize: { xs: "16pt", sm: "18pt", md: "20pt" }, textAlign: "center" }} variant='h4Reg'
          >
            We carry most of the Games Workshop line in the store
          </Typography>
        </Box>

      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{ display: { xs: 'flex' }, justifyContent: { xs: 'center' }, flexWrap: 'wrap', maxWidth: "1248px" }}
        >
          {gameInfoObjects.map((game) => <GamesWorkshopCard gameInfoObject={game} />)}
        </Box>
      </Box>
      <Box sx={{
        mx: { xs: 2, md: 9 },
        my: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{ mx: { xs: 2, }, maxWidth: "800px" }}
          >
            <img src={SWLogo} style={{ width: '100%', borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)" }} />
          </Box>
        </Box>
        {/* the below box contains the cards for each star wars game*/}
        <Box>
          {starWarsGameObjs.map((game) => <StarWarsCard starWarsGame={game} />)}
        </Box>
        <Box sx={{
          mx: { xs: 2, md: 9 },
          my: 7,
        }}>
          <hr style={{
            border: `1px solid ${theme.palette.primary.main}`
          }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{ mx: { xs: 2, }, maxWidth: "800px" }}
          >
            <img src={MarvelLogo} style={{ width: '100%', borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)" }} />
          </Box>
        </Box>
        <Box>
          {marvelGames.map((game) => <StarWarsCard starWarsGame={game} />)}
        </Box>
        <Box sx={{
          mx: { xs: 2, md: 9 },
          my: 7,
        }}>
          <hr style={{
            border: `1px solid ${theme.palette.primary.main}`
          }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <Box sx={{ textAlign: 'center', mx: 2, maxWidth: "1248px" }}>
            <Typography
              sx={{ display: 'block', fontSize: { xs: "20pt", sm: "24pt", md: "30pt" } }} variant='h2Bold'
            >
              RPG Minature: Nolzur's Minis
            </Typography>
            <Typography
              sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
              We carry many Nolzur’s Unpainted Miniatures models for RPGs. If you have a model in mind, let us know and we’ll order it for you at a 10% discount. JACKPOT!
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'flex' }, justifyContent: { xs: 'center' }, flexWrap: 'wrap', }}>
            {miniImages.map((image) => <MiniImgCard miniImg={image} />)}
          </Box>
        </Box>
      </Box>
      <Box sx={{
        mx: { xs: 2, md: 9 },
        my: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ textAlign: "center", maxWidth: "1248px" }}>
          <GameEvents eventObj={eventObj} />
        </Box>
      </Box>
    </>
  )
}

export default MiniatureGaming;