import React from 'react';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

const StarWarsCard = ({ starWarsGame }) => {
  const theme = useTheme();

  return (
    <Box sx={{display: "flex", justifyContent: "center", mt: {xs: 3, md: 6}}}>
      <Box
        sx={{
          mx: { xs: 2 },
          borderRadius: "4px",
          display: {xs: "block", md: "flex"},
          justifyContent: "space-between",
          maxWidth: "1248px"
        }}
      >
        <Box
          sx={{ mx: { xs: 2 }, maxWidth: "600px" }}
        >
          <Typography
            sx={{ display: 'block', fontSize: { xs: "20pt", sm: "24pt", md: "30pt" }, mt: { xs: 2 } }} variant='h2Bold'
          >
            {starWarsGame.name}
          </Typography>
          <Typography
            sx={{fontSize: {xs: "16pt", sm: "18pt", md: "20pt"}}} variant='h4Reg'
          >
            {starWarsGame.description}
          </Typography>
        </Box>
        <Box
          sx={{ mx: { xs: 2 }, my: { xs: 2 }, maxWidth: "600px" }}
        >
          <img
            src={starWarsGame.img}
            alt={starWarsGame.imgAlt}
            style={{
              width: '100%',
              boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)"
            }} />
        </Box>
      </Box>
    </Box>
  )
}

export default StarWarsCard;