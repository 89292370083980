import React from 'react';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const GoogleMapEmbed = () => {
  // sx object should be passed in that styles the box

  return (
    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      style={{ border: "0", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)" }}
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=19555+E+Parker+Square+Dr,+Parker,+CO+80134`}
      allowFullScreen>
    </iframe>
  )
}

export default GoogleMapEmbed;