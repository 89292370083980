import { useEffect } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import NavBar from "./components/global-components/NavBar";
import Footer from "./components/global-components/Footer";
import Home from "./components/HomePage/Home";
import MagicTheGathering from "./components/GamePages/MagicTheGathering";
import RolePlayingGames from "./components/GamePages/RolePlayingGames";
import MiniatureGaming from "./components/GamePages/MiniatureGaming";
import BoardGames from "./components/GamePages/BoardGames";
import Supplies from "./components/GamePages/Supplies";
import Calendar from "./components/MainPages/Calendar";
import FAQ from "./components/MainPages/FAQ";
import ContactUs from "./components/MainPages/ContactUs";
import CardRequestForm from "./components/MainPages/CardRequestForm";
import ScrollToTop from "./components/ScrollToTop";
import "./styles/globalStyles.css"

function App() {
  return (
    <>
      <ScrollToTop/>
      <NavBar/>
        <Routes>
            <Route path="/" element={<Home />} />
             <Route path="/request" element={<CardRequestForm />} />
             <Route path="/calendar" element={<Calendar />} />
             <Route path="/faq" element={<FAQ />} />
             <Route path="/contact" element={<ContactUs />} />
             <Route path="/magic" element={<MagicTheGathering/>} />
             <Route path="/rpg" element={<RolePlayingGames />} />
             <Route path="/miniatures" element={<MiniatureGaming />} />
             <Route path="/boardgames" element={<BoardGames />} />
             <Route path="/supplies" element={<Supplies />} />
        </Routes>
      <Footer/>
    </>
  );
}

export default App;
