import React from 'react';
import { useState, createContext } from "react"

export const GlobalContext = createContext();
export const ContextProvider = ({ children }) => {
    const [requestList, setRequestList] = useState(() => {
      return JSON.parse(localStorage.getItem("cardList")) || [];
    });
    console.log(localStorage.getItem("cardList"));

  return (
    <GlobalContext.Provider
    value = {{
      requestList, setRequestList
    }}
    >
        {children}
    </GlobalContext.Provider>

  );
};
