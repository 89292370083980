import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ChevronDown } from "../../assets/ChevronDown";

const FAQ = () => {
  const theme = useTheme()

  const questions = [
    {
      question: 'What do you collect?',
      answer: 'CollectorMania is a gaming and hobby store that sells new board games, model kits, rule books, and new and used cards. We also carry a wide variety of supplies to supplement each hobby selection we carry. Many of the games we carry are collectable but we do not carry coins, sports cards, antiques or other similar items. We do not sell or trade in video games.'
    },
    {
      question: 'Do you charge for table space?',
      answer: "No, there is no fee to use our space! If we are busy tables will be given to players participating in store events, but most of the time we have enough room for everyone. If you're worried about having table space contact us and ask ahead of time! We do reserve tables for events if you wish to host on your own!"
    },
    {
      question: 'Can I bring in my own food and drink?',
      answer: "You bet! We sell our own drinks, snacks and even frozen meals. You're more than welcome to bring your own food and drink - just try not to make a mess."
    },
    {
      question: 'Can I schedule my own events at your shop?',
      answer: "Yes! If you ever want to schedule something at our store let us know. As long as we have the room available, we'll set aside space for you to run your events. If you give us enough notice, we can even help advertise your event though our website, our Facebook page, and in store!"
    },
    {
      question: 'Are you hiring?',
      answer: "We are not hiring at the moment, but you can send us your resume and if a position should become available, we’ll have your information on file!"
    },

  ];
  return (
    <>
      <Box sx={{ textAlign: "center", mt: 3}}>
        <Typography sx={{fontSize: {xs: "32pt", sm: "48pt", md: "60pt"}}} variant='specHeader'>FAQs</Typography>
      </Box>
      <Box sx={{
        mx: {xs: 0, sm: 6, md: 9, lg: 12, xl: 50},
        display: "flex",
        flexDirection: "column",
        gap: 4,
        my: {xs: 3, sm: 6}
      }}>
        {questions.map((question, index) => (
          <Box sx={{ px: 3, }} key={index}>
            <Accordion
              sx={{
                px: 1,
                py: 2,
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                boxShadow: "0 4px 4px rgba(0, 0, 0, 15%), -8px 8px 15px rgba(53, 50, 44, 15%)"
              }}>
              <AccordionSummary expandIcon={<ChevronDown />}>
                <Typography sx={{cursor: "pointer", fontSize: "18pt"}} variant='h2Bold'>{question.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{fontSize: "16pt"}} variant='h4Reg'>{question.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </>
  )

}

export default FAQ;