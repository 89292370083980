import React from 'react';
import { Box } from "@mui/material";
import {useEffect, useState} from 'react';
import CarouselImg1 from "../../assets/CarouselImg1.jpg"
import CarouselImg2 from "../../assets/CarouselImg2.jpg"
import CarouselImg3 from "../../assets/CarouselImg3.jpg"
import CarouselImg4 from "../../assets/CarouselImg4.jpg"


const MainPhotoCarousel = () => {
    const photos = [CarouselImg1, CarouselImg2, CarouselImg3, CarouselImg4]
    const [photoIdx, setPhotoIdx] = useState(0)

    useEffect(() => {
        const timer = setTimeout(() => {
            setPhotoIdx((currentIdx) => currentIdx + 1);
        }, 5000);

        // Clear the timeout when the component is unmounted or if dependencies change
        return () => clearTimeout(timer);
    }, [photoIdx]);

    return (
        <Box >
            <img
            style={{
                boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)",
                transitionTimingFunction: "ease-in-out",
                transitionDuration: "0.5s",
                width: "100%",
                height: "auto"
            }}
            src={photos[photoIdx % photos.length]} 
            alt="Image" />
        </Box>
    )
}


export default MainPhotoCarousel;
