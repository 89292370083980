import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

const Iframe = () => {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    console.log(matches);

    const desktopSrc = "https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FDenver&showTitle=0&showTz=0&showCalendars=0&showPrint=0&mode=MONTH&src=Y29sbGVjdG9ybWFuaWFwYXJrZXJAZ21haWwuY29t&color=%233F51B5";
    const mobileSrc = "https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FDenver&mode=AGENDA&showTitle=0&showPrint=0&showCalendars=0&showTz=0&src=Y29sbGVjdG9ybWFuaWFwYXJrZXJAZ21haWwuY29t&color=%233F51B5";
  
    const iframeSrc = matches ? desktopSrc : mobileSrc;
  
    return (
      <iframe src={iframeSrc} title="Responsive Content" style={{height: "100%", width: "100%", border: "none"}}></iframe>
    );
}

export default Iframe;