import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import Typography from "@mui/material/Typography";
import GoogleMapEmbed from "../HomePage/GoogleMapEmbed";
import Iframe from "./Iframe";
import useMediaQuery from '@mui/material/useMediaQuery';

const Calendar = () => {
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('xs'));

  return (
    <Box 
    sx={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      px: 1 }}>
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography sx={{ fontSize: { xs: "32pt", sm: "48pt", md: "60pt" } }} variant='specHeader'>Calendar</Typography>
      </Box>
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
          Here’s our shop calendar for all events, tournaments, releases, and more!
        </Typography>
      </Box>
      <Box sx={{
        p: 2,
        my: 3,
        width:  {xs: "100%", xl: "1500px"},
        height: {xs: "450px", sm: "600px", md: "900px", lg: "1000px"},
      }}>
        <Iframe/>
      </Box>
    </Box>
  )


}

export default Calendar;