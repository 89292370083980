import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createEmailBody } from "../templates";
import { sendEmail } from "../../middlewear/awsHelpers";
import { GlobalContext } from '../../context';
import CardDisplay from "./CardDisplay";
import { Search } from "../../assets/Search";
import { Close } from "../../assets/Close";
import { LogoSVG } from "../../assets/Logo";

const CardRequestForm = () => {
  const theme = useTheme();
  const { requestList, setRequestList } = useContext(GlobalContext);
  const [searchVal, setSearchVal] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [cardSets, setCardSets] = useState([]);
  const [searchError, setSearchError] = useState(null);
  const [requestFirstName, setRequestFirstName] = useState('');
  const [requestEmail, setRequestEmail] = useState('');
  const [requestLastName, setRequestLastName] = useState('');
  const [requestError, setRequestError] = useState({});
  const [reqQuant, setReqQuant] = useState(1);



  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchVal.length < 1) {
      setSearchError('Please enter a card to search.')
      return;
    } else {
      setSearchError(null);
    }
    try {
      const response = await fetch(`https://api.scryfall.com/cards/named?fuzzy=${searchVal}`);
      if (!response.ok) {
        console.error(response);
        setSearchError('Unable to find anything matching that search, only use a card name.')
        return;
      }
      const card = await response.json();
      let searchedCard;
      // middle functions to deal with 2 faced and 1 faced cards
      if (!card.colors) {
        const colors = getTwoFaceColors(card);
        const multiCardSets = await getPrints(card);
        searchedCard = {
          colorScheme: colors,
          name: [card.card_faces[0].name, card.card_faces[1].name],
          image_uris: [card.card_faces[0].image_uris, card.card_faces[1].image_uris],
          sets: multiCardSets,
          quantity: 1,
        }
      } else {
        const gotSets = await getPrints(card);
        if (!gotSets) {
          setSearchError('Something went wrong, please try again later.')
          return;
        }
        card.colorScheme = setColors(card.colors);
        searchedCard =
        {
          name: [card.name],
          image_uris: [card.image_uris],
          colorScheme: [card.colorScheme],
          sets: gotSets,
          quantity: 1,
        }
      }
      setSearchResults(searchedCard);  // Set the card data as an array for future compatibility
      // Fetch sets of the card
    } catch (error) {
      console.error(error);
    }
    setSearchVal('');
  }

  const getTwoFaceColors = (card) => {
    const colorSet1 = setColors(card.card_faces['0'].colors);
    const colorSet2 = setColors(card.card_faces['1'].colors);
    return [colorSet1, colorSet2];
  }


  const setColors = (colors) => {
    if (colors.length === 0) {
      return 'Land';
    } else if (colors.length > 1) {
      return 'Multicolor';
    }
    switch (colors[0]) {
      case 'W':
        return 'White'
      case 'U':
        return 'Blue'
      case 'B':
        return 'Black'
      case 'R':
        return 'Red'
      case 'G':
        return 'Green'
      default:
        return 'Multicolor';
    }
  }
  const getPrints = async (card) => {
    try {
      const response = await fetch(card.prints_search_uri);
      if (!response.ok) {
        console.error(response);
        return;
      }
      const data = await response.json();
      return removeDuplicateSets(data.data);

    } catch (error) {
      console.error(error);
      return []
    }
  }

  const removeDuplicateSets = (setArr) => {
    let currentSets = {};
    let finalSets = [];
    for (const card of setArr) {
      if (!currentSets.hasOwnProperty(card.set_name)) {
        finalSets.push(card.set_name);
        currentSets[card.set_name] = true;
      }
    }
    console.log(finalSets)
    return finalSets.sort();
  }

  const handleAddCard = (card) => {
    const requestedCard =
    {
      name: card.name,
      image_uris: card.image_uris,
      colorScheme: card.colorScheme,
      sets: card.sets,
      quantity: card.quantity,

    }
    console.log('REQ', requestedCard);
    const newList = [...requestList, requestedCard];
    setRequestList(newList);
    localStorage.setItem("cardList", JSON.stringify(newList));
  }

  const handleRemoveCard = (card) => {
    const name = card.name;
    const newList = requestList.filter((item) => item.name !== name);
    setRequestList(newList);
    localStorage.setItem("cardList", JSON.stringify(newList));
  }

  const validateRequest = () => {
    let errors = {};

    if (!requestFirstName) {
      errors.firstName = 'Please enter your first name.';
    }
    if (!requestLastName) {
      errors.lastName = 'Please enter your last name.';
    }
    if (!requestEmail) {
      errors.email = 'Please enter your email.';
    }
    if (requestList.length < 1) {
      errors.requestList = 'Please add cards to your request list.';
    }


    setRequestError((prev) => ({ ...prev, ...errors }));

    return Object.keys(errors).length === 0;
  }

  const handleClearCardList = () => {
    setRequestList([]);
    localStorage.setItem("cardList", JSON.stringify([]));
  }

  const handleRequestSubmit = async (e) => {
    e.preventDefault();
    if (!validateRequest()) {
      return;
    }

    const cardTableRows = requestList.map((card) => {
      const setsList = card.sets.map(set => `<li>${set}</li>`).join('');
      return `
    <tr style="border-bottom: 1px solid black">
        <td style="text-align: left;">${card.name[0]}${card.name[1] ? `, ${card.name[1]}` : ""}</td>
        <td style="text-align: center;">${card.quantity}</td>
        <td style="text-align: center;">${card.colorScheme[0]}${card.colorScheme[1] ? `, ${card.colorScheme[1]}` : ""}</td>
        <td style="text-align: left;"><ul style="list-style-type: none; padding: 0; margin: 0;">${setsList}</ul></td>
    </tr>`;
    }).join('');

    const emailBody = `
<p>Name: ${requestFirstName} ${requestLastName}</p>
<p>Email: ${requestEmail}</p>
<table style="width: 100%; border-collapse: collapse;">
    <thead>
        <tr>
            <th style="text-align: left;">Name</th>
            <th style="text-align: center;">Quantity</th>
            <th style="text-align: center;">Color</th>
            <th style="text-align: left;">Sets</th>
        </tr>
    </thead>
    <tbody>
        ${cardTableRows}
    </tbody>
</table>`;


    const emailObj = {
      to: "collectormaniaparker@gmail.com", // Replace with the recipient's email
      subject: "Card Request Form",
      body: emailBody
    };

    const response = await sendEmail(emailObj);
    setRequestEmail('');
    setRequestFirstName('');
    setRequestLastName('');
    setRequestList([]);
    localStorage.setItem("cardList", JSON.stringify([]));
    console.log(response.message);
  }

  useEffect(() => {
    setRequestError({});
  }, [requestFirstName, requestLastName, requestEmail, requestList])

  useEffect(() => {
    setSearchError('');
  }, [searchVal])


  return (
    <Box>
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography sx={{ fontSize: { xs: "32pt", sm: "48pt", md: "60pt" } }} variant='specHeader'>
          Card Request Form
        </Typography>
      </Box>
      <Box sx={{ mx: 2, my: 3 }}>
        <Box sx={{textAlign: "center"}}>
          <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
            If you're looking for a specific card to fill out your collection, or multiple cards to build out a killer deck, you've come to the right place!
          </Typography>
        </Box>
        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
            Fill out this form with your name and email and send it over to us. We will scour our inventory and, if we have them in stock, set them aside for you to come pick up. It's that simple!
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        mx: 2,
        my: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <form onSubmit={handleSearch}>
        <Box
          sx={{
            mx: 1,
            display: "flex",
            justifyContent: "center",
          }}>
          <TextField
            sx={{
              width: "100%",
              maxWidth: "650px",
            }}
            id="outlined-basic"
            label="Search for a card"
            value={searchVal}
            variant="outlined"
            onChange={(e) => { setSearchVal(e.target.value) }}>
          </TextField>
          <Button
            type='submit'
            variant='primary'
          >
            <Search
              width={"40px"}
              color={theme.palette.bodyTextNegative.main} />
          </Button>
        </Box>
        <Box sx={{textAlign: "center", mt: 1}}>
          {searchError ?
            <Typography variant="h4Reg" sx={{ fontSize: "16pt", color: theme.palette.secondary.error2}}>
              {searchError}
            </Typography>
            : null}
        </Box>
      </form>
      <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <Box sx={{ p: 1, my: 3 }}>
          {/*  Search Results box  */}
          <Typography sx={{ fontSize: { xs: "20pt", sm: "20pt", md: "24pt" } }} variant='h2Bold'>
            Results
          </Typography>
        </Box>
        <Box sx={{ px: 2 }}>
          {
            searchResults.name ?
              <CardDisplay card={searchResults} list='add' onClick={handleAddCard} />
              : null
          }
        </Box>
      </Box>
      <Box sx={{
        mx: 2,
        my: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{ p: 1, my: 3 }}>
        {/* Current Requested Cards */}
        <Typography sx={{ fontSize: { xs: "20pt", sm: "20pt", md: "24pt" } }} variant='h2Bold'>
          My Card Request List
        </Typography>
        <Box sx={{ pl: 2 }}>
          <Button
            endIcon={<Close width={18} color={theme.palette.bodyTextNegative.main} />}
            sx={{my: 3}}
            variant='danger'
            onClick={handleClearCardList}
          >
            Clear List
          </Button>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "flex" }, justifyContent: "space-evenly", flexWrap: { sm: "wrap" } }}>
          {
            requestList.length > 0
              ? requestList.map((card) =>
                <CardDisplay card={card
                } list='remove' onClick={handleRemoveCard} />)
              : null}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ display: { md: "flex" }, justifyContent: "space-between", maxWidth: "1040px", minWidth: { xs: "100%", md: "1000px" } }}>
          {/* this is to hold the form to send off an email with your request  (MAYBE FLEX THIS BITCH) */}
          <Box >
            <form onSubmit={handleRequestSubmit}>
              <Box sx={{ textAlign: "left", pl: 2 }}>
                <Typography sx={{ fontSize: { xs: "20pt", sm: "20pt", md: "24pt" } }} variant='h2Bold'>
                  Fill out your name and email below
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mx: 1, mt: 2 }}>
                {requestError.request ? <Typography>{requestError.request}</Typography> : null}
                <TextField
                  fullWidth
                  label="First Name"
                  onChange={(e) => (setRequestFirstName(e.target.value))} value={requestFirstName}></TextField>
                {requestError.firstName ? <Typography variant="h4Reg" sx={{ fontSize: { xs: "14pt", sm: "16pt", md: "16pt" }, color: theme.palette.secondary.error2}}>{requestError.firstName}</Typography> : null}
                <TextField
                  fullWidth
                  label="Last Name"
                  onChange={(e) => (setRequestLastName(e.target.value))} value={requestLastName}></TextField>
                {requestError.lastName ? <Typography variant="h4Reg" sx={{ fontSize: { xs: "14pt", sm: "16pt", md: "16pt" }, color: theme.palette.secondary.error2}}>{requestError.lastName}</Typography> : null}
                <TextField
                  fullWidth
                  label="Email"
                  onChange={(e) => (setRequestEmail(e.target.value))} value={requestEmail}></TextField>
                {requestError.email ? <Typography variant="h4Reg" sx={{ fontSize: { xs: "14pt", sm: "16pt", md: "16pt" }, color: theme.palette.secondary.error2}}>{requestError.email}</Typography> : null}
              </Box>
              <Box sx={{ mx: 1, my: 3 }}>
                <Button
                  variant='primary'
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <LogoSVG width={"300px"} height={"auto"} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CardRequestForm;