import React from 'react';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import WhatIsGame from "./WhatIsGame";
import GameEvents from "./GameEvents";
import SupplyImageCard from "./SupplyImageCard";
import SuppliesWarhammer40k from "../../assets/SuppliesWarhammer40k.jpg";
import SuppliesDnD from "../../assets/SuppliesDnD.jpg";
import SuppliesDiceTray from "../../assets/SuppliesDiceTray.jpg";
import SuppliesMTG from "../../assets/SuppliesMTG.jpg";

const Supplies = () => {
  const theme = useTheme();

  const supplyCardObjs = [
    {
      image: SuppliesDnD,
      name: 'Dice',
      items: [
        'Polyhedral-7 Dice Sets',
        '12mm Dice Sets',
        '16mm Dice Sets',
        'Resin Dice Sets',
        'Metal Dice Sets',
        'Wood Dice Sets',
      ]
    },
    {
      image: SuppliesMTG,
      name: 'Card Supplies',
      items: [
        'Bulk Storage Boxes',
        'Deck Boxes',
        'Sleeves',
        'Binders',
      ]
    },
    {
      image: SuppliesWarhammer40k,
      name: 'Miniature Supplies',
      items: [
        'Basing Material,' +
        'Brushes',
        'Primer',
        'Paint',
        'Glue',
        'Storage',
      ]
    },
    {
      image: SuppliesDiceTray,
      name: 'Misc.',
      items: [
        'Dice Rolling Mats',
        'RPG Mats',
        'Dice Bags',
        'Dice Trays',
        'Play Mats'
      ]
    }
  ]


  return (
    <Box>
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography sx={{ fontSize: { xs: "32pt", sm: "48pt", md: "60pt" } }} variant='specHeader'>
          Supplies
        </Typography>
        <Box sx={{mt:2}}>
          <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
            We carry supplies for all your gaming adventures
          </Typography>
        </Box>
      </Box>
      <Box
          sx={{
            mx:2,
            mt: 8,
            display: {sm: 'flex'},
            flexWrap: 'wrap',
            justifyContent:'center',
            gap: {md: 4, lg: 8}
          }}
      >
        {supplyCardObjs.map((card) => <SupplyImageCard supplyCardObj={card} />)}
      </Box>
      <Box sx={{my:6, px:3, textAlign:'center', width: {xs:"100%",} }}>
        <Typography
            sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'
        >
          If you need supplies and we don’t have it in store, let us know! We’ll order what you need and take 10% off of your purchase. Cha-CHING!
        </Typography>
      </Box>

    </Box>
  )
}

export default Supplies;