import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from "@emotion/react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ChevronDown } from "../../assets/ChevronDown";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Close } from "../../assets/Close";
import { useState } from "react";


const CardDisplay = ({ card, list, onClick }) => {
  const [quantity, setQuantity] = useState(card.quantity || 1);
  const [quantityError, setQuantityError] = useState(false);
  const theme = useTheme();
  const handleAddCard = (card) => {
    if (quantity < 1) {
      setQuantityError(true);
    } else {
      card.quantity = quantity;
      onClick(card);
      setQuantityError(false)
    }

  }

  const handleRemoveCard = (card) => {
    onClick(card);
  }

  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: "450px" },
        mt: 3,
        borderRadius: "8px",
        p: 2,
        boxShadow: "0 4px 4px rgba(0, 0, 0, 15%), -8px 8px 15px rgba(53, 50, 44, 15%)"
      }}>

      <Box sx={{ display: "flex", flexWrap: { xs: "wrap", md: "nowrap" }, gap: 2 }}>
        {
          card.image_uris.map((item) =>
            <Box sx={{ width: { xs: "220px", md: "386px" }, maxWidth: "386px" }}>
              <img src={item.small} alt={card.name} width="100%" />
            </Box>
          )
        }
      </Box>

      <Box sx={{ mt: 2 }}>
        {
          card.name.map((item, idx) =>
            <Box>
              <Typography variant="h4Reg" sx={{ fontSize: "14pt", display: "block" }}>
                {idx === 0 ? "Front: " : "Back: "}
              </Typography>
              <Typography sx={{ fontSize: "18pt", display: "block" }} variant='h1Bold'>
                {item}
              </Typography>
            </Box>
          )
        }
      </Box>
      <Box sx={{ mt: 3 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ChevronDown />}>
            <Typography sx={{ fontSize: "18pt" }} variant='h2Bold'>
              Sets
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              '&::-webkit-scrollbar': {
                width: '0.2em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "10px"
              },
              maxHeight: "300px",
              overflowY: "scroll"
            }}>
            <List >
              {
                card.sets.length > 0
                  ? card.sets.map((set, idx) =>
                    <ListItem key={idx}>
                      <Typography sx={{ fontSize: "16pt" }} variant='h4Reg'>
                        {set}
                      </Typography>
                    </ListItem>
                  ) : null
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography sx={{ fontSize: "18pt" }} variant='h2Bold'>
          Color Section
        </Typography>
      </Box>
      <Box>
        {
          card.colorScheme.map((item, idx) =>
            <Box sx={{ mt: 3 }} >
              <Typography variant="h4Reg" sx={{ fontSize: "14pt", display: "block" }}>
                {idx === 0 ? "Front: " : "Back: "}
              </Typography>
              <Typography sx={{ fontSize: "18pt", display: "block" }} variant='h4Bold'>
                {item}
              </Typography>
            </Box>
          )
        }
      </Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: { xs: "space-between", sm: "space-evenly" }, mt: 3, alignItems: "flex-end" }}>
          <Box>
            {list == 'add'
              ? <TextField sx={{ width: "100px" }} label='Quantity' value={quantity} type="number" onChange={(e) => (setQuantity(e.target.value))}></TextField>
              : <TextField sx={{ width: "100px" }} label='Quantity' value={quantity} type="number"></TextField>
            }
          </Box>
          <Box>
            {
              list === 'add'
                ? <Button
                    onClick={() => (handleAddCard(card))}
                    variant='primary'
                  >
                    Add Card
                  </Button>
                : <Button
                  endIcon={<Close width={18} color={theme.palette.bodyTextNegative.main} />}
                  onClick={() => (handleRemoveCard(card))}
                  sx={{py:1.25}}
                  variant='danger'
                  >
                    Remove Card 
                  </Button>
            }
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          {
            quantityError
              ?
              <Typography
                variant="h4Reg"
                sx={{
                  fontSize: "16pt",
                  display: "block",
                  color: theme.palette.secondary.error2
                }}>
                Card Quantity cannot be less than 1.
              </Typography>
              :
              null
          }
        </Box>
      </Box>
    </Box>
  )
};

export default CardDisplay;