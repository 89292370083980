import React from 'react';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import GameEvents from "./GameEvents";
import WhatIsGame from "./WhatIsGame";
import GamesWorkshopCard from "./GamesWorkshopCard";
import BoardGame1 from '../../assets/BoardGame1.jpg';
import BoardGame2 from '../../assets/BoardGame2.jpg';
import FantastFlight from '../../assets/Fantasy Flight Logo Img.jpg';
import RioGrande from '../../assets/Rio Grande Games Logo Img.jpg';
import DaysOfWonder from '../../assets/DaysofWonderLogo.jpg';
import Mayfair from '../../assets/Mayfair Games Logo Img.jpg';
import Hasbro from '../../assets/Hasbro Logo Img.jpg';
import GameTrade from '../../assets/GTM Logo Img.jpg';
import FunForge from '../../assets/Fun Forge Logo Img.jpg';
import ZMan from '../../assets/Z Man Logo Img.jpg';
import BoardGameEvent from '../../assets/Shop Img Container.jpg'



const BoardGames = () => {
    const theme = useTheme();
    const gameObj = {
        title: null,
        description: 'Board games have been around for ages. They appeal to people of all ages and experience levels. There are many types of board games that have been invented over the decades. \n ' +
            'We like to honor the classics, as well as embrace the modern board games that have come about in recent years.',
        image: BoardGame2,
        alt: 'Dice on Table',
    }
    const gameCompanies = [
        {
            title: 'Fantasy Flight Games',
            img: FantastFlight,
            alt: 'Fantasy Flight Games logo'
        },
        {
            title: 'Rio Grande Games',
            img: RioGrande,
            alt: 'Rio Grande Games logo'
        },
        {
            title: 'Days of Wonder',
            img: DaysOfWonder,
            alt: 'Days of Wonder logo'
        },
        {
            title: 'Mayfair Games',
            img: Mayfair,
            alt: 'Mayfair Games logo'
        },
        {
            title: 'Hasbro',
            img: Hasbro,
            alt: 'Hasbro logo',
        },
        {
            title: 'GTM Games',
            img: GameTrade,
            alt: 'GTM Games logo'
        },
        {
            title: 'Fun Forge',
            img: FunForge,
            alt: 'Fun Forge logo'
        },
        {
            title: 'Z-Man Games',
            img: ZMan,
            alt: 'Z-Man Games logo'
        }
    ]
    const eventObj = {
        title: 'Board Game Events',
        description: 'We regularly schedule multiple events each week, giving everyone plenty of opportunity to play.',
        img: BoardGameEvent,
        tagline: 'Check our calendar for upcoming events and tournaments and get in on the action!'


    }

    return (
        <>
            <Box
                sx={{ textAlign: "center", mt: 3, my: { xs: 3, lg: 7 } }}
            >
                <Typography
                    sx={{ fontSize: { xs: "26pt", sm: "48pt", md: "60pt" } }} variant='specHeader'
                >
                    Board Games
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: { xs: '100%', md: '1248px' }, px: { xs: 2, md: 6 }, }} >
                    <img src={BoardGame1} width={"100%"} style={{ borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)" }} />
                </Box>
            </Box>

            <Box sx={{ mt: 6 }}>
                <WhatIsGame gameObj={gameObj} />
            </Box>
            <Box sx={{
                mx: { xs: 2, md: 9 },
                mt: 7,
            }}>
                <hr style={{
                    border: `1px solid ${theme.palette.primary.main}`
                }} />
            </Box>
            <Box sx={{ textAlign: 'center' }}>

                <Typography
                    sx={{ fontSize: { xs: "20pt", sm: "24pt", md: "30pt" }, textAlign: 'center' }}
                    variant='h2Bold'
                >
                    We focus carrying board games from these companies
                </Typography>
                <Box
                    sx={{ display: { xs: 'flex' }, justifyContent: { xs: 'center' }, flexWrap: 'wrap', }}
                >
                    {gameCompanies.map((gameComp) => <GamesWorkshopCard gameInfoObject={gameComp} />)}
                </Box>
                <Box sx={{
                    mx: { xs: 2, md: 9 },
                    mt: 7,
                }}>
                    <hr style={{
                        border: `1px solid ${theme.palette.primary.main}`
                    }} />
                </Box>
                <Box>
                    <GameEvents eventObj={eventObj} />
                </Box>
            </Box>
        </>
    )

}

export default BoardGames;