import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';

// Provide your AWS credentials here
const ACCESS_KEY = 'YOUR_ACCESS_KEY';
const SECRET_KEY = 'YOUR_SECRET_KEY';

const sesClient = new SESClient({
    region: 'us-west-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET
    }
});

export const sendEmail = async (emailObj) => {
    const emailParams = {
        Destination: {
            ToAddresses: [emailObj.to]
        },
        Message: {
            Body: {
                Html: {
                    Charset: 'UTF-8',
                    Data: emailObj.body
                }
            },
            Subject: {
                Charset: 'UTF-8',
                Data: emailObj.subject
            }
        },
        Source: "collectormaniaparker@gmail.com"
    };
    try {
        const data = await sesClient.send(new SendEmailCommand(emailParams));
        return { message: `Email sent! Message ID: ${data.MessageId}` };
    } catch (error) {
        console.log(error);
        return { message: `Error sending email: ${error}` }
    }
}
