import React from 'react';
import Typography from '@mui/material/Typography'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import WhatIsGame from "./WhatIsGame";
import GameEvents from "./GameEvents";
import DNDLogo from "../../assets/DNDLogo.jpg";
import DnDArt1 from "../../assets/DnDArt1.jpg";
import DNDArt2 from "../../assets/DNDArt2.jpg";
import DNDArt3 from "../../assets/DNDArt3.jpg";
import { ChevronDown } from "../../assets/ChevronDown";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ChevronRight } from '../../assets/ChevronRight';



const RolePlayingGames = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const gameObj = {
    title: 'What is Dungeons & Dragons?',
    description: 'Dungeons and Dragons is a fantasy tabletop role-playing game made by Wizards of the Coast.',
    description2:
      'Create a character and take on their identity in an epic adventure across a fantasy world built by your Dungeon Master. Form a party with other players and write your epic tale that will echo the ages! Solve problems, engage in battles, explore, and gather that sweet, sweet treasure.',
    description3:
      'As you interact with the world and endure grueling fights, you gain experience and level up, gaining access to incredibly powerful spells and abilities.',
    image: DNDArt2,
    alt: 'ALT DESC'
  }

  const eventObj = {
    title: 'Role Playing Game Events',
    description: 'We regularly schedule multiple events each week, giving everyone plenty of opportunity to play.',
    img: DNDArt3,
    tagline: 'Check our calendar for upcoming events and tournaments and get in on the action!',
  }

  // all the books bruh

  const booksObject = {

    "Core Rule Books": ["Dungeon Master’s Guide", "Monster Manual", "Player’s Handbook"],

    "Supplements":
      ["Bigby Presents: Glory of the Giants", "Fizban’s Treasury of Dragons", "Monsters of the Multiverse", "Mordenkainen’s Tome of Foes", "Tasha’s Cauldron of Everything", "Volo’s Guide to Monsters", "Xanathar’s Guide to Everything"],

    "Campaign Settings":
      ["Acquisitions Incorporated", "Eberron: Rising from the Last War", "Explorer’s Guide to Wildemount", "Guildmasters’ Guide to Ravnica", "Mythic Odysseys of Theros", "Spelljammer: Adventures in Space", "Strixhaven: A Curriculum of Chaos", "Sword Coast Adventurer’s Guide", "Van Richten’s Guide to Ravenloft"],

    "Adventures":
      ["Baldurs Gate: Descent into Avernus", "Candlekeep Mysteries", "Critical Role: Call of the Netherdeep", "Curse of Strahd", "Dragonlance: Shadow of the Dragon Queen", "Dungeons & Dragons vs Rick and Morty", "Essentials Kit", "Ghosts of Saltmarsh", "Hoard of the Dragon Queen", "Icewind Dale: Rime of the Frostmaiden", "Journeys Through the Radiant Citadel", "Keys from the Golden Vault", "Out of the Abyss", "Princes of the Apocalypse", "Starter Set: Dragons of the Stormwreck Isle", "Storm King’s Thunder", "Stranger Things: The Hunt for Thessalhydra", "Tales from the Yawning Portal", "The Rise of Tiamat", "Tomb of Annihilation", "Tyranny of the Dragons Re-release", "Waterdeep: Dragon Heist", "Waterdeep: Dungeon of the Mad Mage", "Wild Beyond the Witchlight"]
  }


  return (
    <Box sx={{ mx: 2 }}>
      <Box
        sx={{ textAlign: "center", mt: 3, my: { xs: 3, lg: 7 } }}
      >
        <Typography
          sx={{ fontSize: { xs: "26pt", sm: "48pt", md: "60pt" } }} variant='specHeader'
        >
          Role Playing Games
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", }}>
        <Box sx={{ maxWidth: "425px", }}>
          <img src={DNDLogo} alt="DnD Logo" />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", my: { xs: 3, lg: 7 } }}>
        <Box sx={{ maxWidth: "1248px", minHeight: "200px" }}>
          {/* IMG TAG BELOW IS THE MAIN IMAGE ON THE PAGE */}
          <img style={{ borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)" }} width={"100%"} height={"100%"} src={DnDArt1} alt="DnD Art Main image" />
        </Box>
      </Box>
      <WhatIsGame gameObj={gameObj} />
      <Box sx={{
        mx: { xs: 2, md: 9 },
        mt: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{display: "flex", justifyContent: "center"}}>
        <Box sx={{ mx: {xs: 2, lg: 9}, maxWidth: "1248px" }}>
          {/* This should hold the unique part of the page */}
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ fontSize: { xs: "24pt", sm: "24pt", md: "30pt" } }} variant='h2Bold'>
              We Have the Books
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
              We carry the full line of Dungeons and Dragons 5th Edition rulebooks, supplements and extras!
            </Typography>
          </Box>
          <Box sx={{mt: 3}}>
            <Typography sx={{ fontSize: { xs: "24pt", sm: "24pt", md: "30pt" } }} variant='h2Bold'>
              Here’s a list
            </Typography>
          </Box>
          <Box>
            {
              Object.keys(booksObject).map((key) =>
                <Accordion sx={{ mt: 2, maxWidth: {xs: "100%", md: "900px"}}}>
                  <AccordionSummary
                    sx={{
                      cursor: "pointer",
                      py: 1,
                      borderRadius: "4px"
                    }}
                    expandIcon={<ChevronDown />}>
                      <Typography variant='h2Bold' sx={{cursor: "pointer", fontSize: "18pt"}}>
                        {key}
                      </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      '&::-webkit-scrollbar': {
                        width: '0.2em',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "10px"
                      },
                      maxHeight: "300px",
                      overflowY: "scroll"
                    }}>
                    <List>
                      {booksObject[key].map((title) =>
                        <ListItem>
                          <Typography sx={{ fontSize: "16pt" }} variant='h4Reg'>
                            {title}
                          </Typography>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              )
            }
          </Box>
          <Box sx={{ my: 6 }}>
            <Box>
              <Typography sx={{ fontSize: { xs: "24pt", sm: "24pt", md: "30pt" } }} variant='h2Bold'>
                Dungeons and Dragons Supplies
              </Typography>
            </Box>
            <Box sx={{ my: 3}}>
              <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
                We carry all the supplies for your adventures - Sleeves, dice, mats, paints, brushes, glue, and more!
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => navigate("/supplies")}
                endIcon={<ChevronRight color={theme.palette.background.default} />}
                sx={{mt: 3}}
                variant='primary'
              >
                Supplies
              </Button>
            </Box>
          </Box>
          <Box sx={{
            mx: { xs: 2, md: 9 },
            mt: 7,
          }}>
            <hr style={{
              border: `1px solid ${theme.palette.primary.main}`
            }} />
          </Box>
          <Box sx={{textAlign: "center", my: 3}}>
            <Typography sx={{ fontSize: { xs: "24pt", sm: "24pt", md: "30pt" } }} variant='h2Bold'>
              Other RPGs
            </Typography>
          </Box>
          <Box sx={{textAlign: "center"}}>
            <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
              There are countless RPG worlds out there to explore - far too many for us to carry all of them in store. If you’re looking for something specific, reach out and let us know. We’ll order it for you at a 10% discount. Did someone say SAVINGS?!
            </Typography>
          </Box>
          <Box sx={{
            mx: { xs: 2, md: 9 },
            mt: 7,
          }}>
            <hr style={{
              border: `1px solid ${theme.palette.primary.main}`
            }} />
          </Box>
        </Box>
      </Box>
      <Box sx={{textAlign: "center"}}>
        <GameEvents eventObj={eventObj} />
      </Box>
    </Box>
  )
}

export default RolePlayingGames;