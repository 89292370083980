import React from 'react';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@emotion/react';
import WhatIsGame from "./WhatIsGame";
import GameEvents from "./GameEvents";
import MTGArt from "../../assets/MTGArt.jpg"
import MTGArt2 from "../../assets/MTGArt2.jpg"
import MTGCards from "../../assets/MTGCards.jpg";
import MTGHoldingCards from "../../assets/MTGHoldingCards.jpg";
import MagicTheGatheringLogo from "../../assets/MagicTheGatheringLogo.jpg"
import { ChevronRight } from "../../assets/ChevronRight";

const MagicTheGathering = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const gameObj = {
    title: 'What is Magic: The Gathering?',
    description: "Magic: The Gathering is a tabletop and digital collectable card game made by Wizards of the Coast.",

    description2: "Assume the role of a Planeswalker, a powerful wizard who can travel ('walk') between dimensions ('planes') of the Multiverse, doing battle with other players as Planeswalkers by casting spells, using artifacts, and summoning creatures as depicted on individual cards drawn from their individual decks.",

    description3: "A player defeats their opponent typically (but not always) by casting spells and attacking with creatures to deal damage to the opponent's 'life total', with the objective being to reduce it from 20 to 0, or 40 to 0 in some group formats.",
    image: MTGArt2,
    alt: 'Image Description here',
  }

  const eventObj = {
    title: 'Magic the Gathering Events',
    description: 'We regularly schedule multiple events each week, giving everyone plenty of opportunity to play. Everyone who participates in tournaments receive prizes, regardless of placing! The higher you place, the more you get, so bring your A game.',
    img: MTGHoldingCards,
    tagline: 'Check our calendar for upcoming events and tournaments and get in on the action!',
  }

  return (
    <Box sx={{ mx: 2 }}>
      <Box
        sx={{ textAlign: "center", mt: 3, my: { xs: 3, lg: 7 } }}
      >
        <Typography
          sx={{ fontSize: { xs: "26pt", sm: "48pt", md: "60pt" } }} variant='specHeader'
        >
          Magic The Gathering
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", }}>
        <Box sx={{ maxWidth: "425px", }}>
          <img src={MagicTheGatheringLogo} width={"100%"} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", my: { xs: 3, lg: 7 } }}>
        <Box sx={{ maxWidth: "1248px"}}>
          <img style={{ borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)"}} src={MTGArt} width={"100%"} />
        </Box>
      </Box>
      <WhatIsGame gameObj={gameObj} />
      <Box sx={{
        mx: { xs: 2, md: 9 },
        mt: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", my: 6 }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3, mx: { xs: 2, md: 4, lg: 7, xl: 9 }, maxWidth: "1248px" }}>
          <Box sx={{ flexDirection: "column", display: "flex", gap: 3, flex: 1 }}>
            <Box>
              <Box>
                <Typography sx={{ fontSize: { xs: "24pt", sm: "24pt", md: "30pt" } }} variant='h2Bold'>
                  We buy cards and collections
                </Typography>
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
                  Do you have MTG cards or a sweet collection that you want to sell? Bring in your collection and we will make you an offer for the cards we need. It’s that simple.
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: { xs: "14pt", md: "16pt" } }} variant='h4Reg'>
                  *If your collection is large, please call ahead to ensure we have ample time to go through your cards before you come in
                </Typography>
              </Box>
              <Box>
                <Button
                  onClick={() => navigate("/contact")}
                  endIcon={<ChevronRight color={theme.palette.background.default} />}
                  sx={{mt:6}}
                  variant='primary'
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
            <Box>
              <Box sx={{ my: 3 }}>
                <Typography sx={{ fontSize: { xs: "24pt", sm: "24pt", md: "30pt" } }} variant='h2Bold'>
                  Card Requests
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: { xs: "16pt", sm: "18pt", md: "20pt" } }} variant='h4Reg'>
                  Looking for a MTG card to fill out those gaps in your collection? Or maybe you need a number of cards to build that deck you’ve been dreaming of.
                  Send us a Card Request Form and we will check our inventory and procure the cards you need!
                </Typography>
              </Box>
              <Box>
                <Button
                  onClick={() => navigate("/request")}
                  endIcon={<ChevronRight color={theme.palette.background.default} />}
                  sx={{mt:6}}
                  variant='primary'
                >
                  Card Request Form
                </Button>
              </Box>
            </Box>
          </Box>
          <Box>
            {/*This is the cards image from the middle*/}
            <img style={{ borderRadius: "4px", boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)"}} width="100%" src={MTGCards} />
          </Box>
        </Box>
      </Box>
      <Box sx={{
        mx: { xs: 2, md: 9 },
        mt: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box >
        <GameEvents eventObj={eventObj} />
      </Box>
    </Box>
  )
}

export default MagicTheGathering;